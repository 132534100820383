// Customizable Area Start
import React from "react";
import { Button, Typography, Input } from "@mui/material";

import MessageList from "./MessageList";

const configJSON = require("./config");

const MeetingChatView = ({ publish, messages, message, setMessage }) => {
  return (
    <div style={webStyle.container}>
      <Typography variant="h5" style={webStyle.title}>
        {configJSON.chatLabel}
      </Typography>

      <div style={webStyle.textInputWrapper}>
        <Input
          value={message}
          onChange={(e) => {
            const v = e.target.value;
            setMessage(v);
          }}
          data-testid="messageTextInput"
          style={webStyle.textInput}
          placeholder="Type your message here"
        />
        <Button
          style={webStyle.sendBtn}
          color="primary"
          variant="contained"
          data-testid="messageSendBtn"
          onClick={() => {
            const m = message;
            m.length && publish(m, { persist: true });
            m.length && setMessage("");
          }}
        >
          {configJSON.sendLabel}
        </Button>
      </div>
      <MessageList messages={messages} />
    </div>
  );
};

const webStyle = {
  container: {
    width: 300,
    backgroundColor: "#ddd",
    overflowY: "scroll",
    marginLeft: 8,
    borderRadius: 8,
    height: `calc(100vh - ${configJSON.toolbarHeight + 2 * 8}px)`,
    padding: 8,
  },
  title: {
    marginBottom: "15px",
  },
  textInputWrapper: {
    display: "flex",
  },
  textInput: {
    borderRadius: "5px",
    width: "300px",
  },
  sendBtn: {
    marginLeft: "25px",
    marginRight: 0,
    textAlign: "right",
  },
};

export default MeetingChatView;
// Customizable Area End
