import React, { Component } from 'react';
import {
  Box,
  Button,
  Input,
  Typography
} from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface Props {
  navigation: any;
}

export const TypographyView = styled(Typography)({
	'& *': {
		fontFamily: "Roboto",
		fontWeight: 400,
	},
	'& .footer': {
		backgroundColor: '#350859',
		backgroundImage: `url(${require("./footer_bg_img.png")})`,
		backgroundSize: 'cover',
		padding: '40px',
		'@media (max-width: 414px)': {
			padding: '20px 16px',
		},
		'@media (max-width: 1000px)': {
			padding: '24px',
		}
	},
	'& .footerContainer': {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		gap:'20px',
		'@media (max-width: 414px)': {
			flexDirection: 'column',
		}
	},
	'& .footerLogo': {
		height: '100px',
		width: '100px',
		backgroundColor: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '20px',
		'@media (max-width: 414px)': {
			height: '80px',
			width: '80px',
		}
	},
	'& .footerLogo img': {
		height: '50px',
		width: '74px',
		'@media (max-width: 414px)': {
			height: '40px',
			width: '50px',
		}
	},
	'& .footerLinksContainer': {
		display: 'flex',
		flexDirection: 'column',
		gap: '10px',
	},
	'& .footerLinksContainer .quickLinks, & .footerLinksContainer button': {
		fontSize: '16px',
		fontWeight: 500,
		lineHeight: '19px',
		color: '#fff !important',
		justifyContent: 'flex-start',
		padding: 0,
		'@media (max-width: 414px)': {
			fontSize: '12px',
			lineHeight: '15px',
		},
		'@media (max-width: 1280px)': {
			fontSize: '14px',
			lineHeight: '17px',
		},
		'@media (min-width: 1600px)': {
			fontSize: '18px',
			lineHeight: '21px',
		}
	},
	'& .quickLinks': {
		fontWeight: '700 !important',
		lineHeight: '28px !important',
	},
	'& .subscribeContainer': {
		height: '165px',
		backgroundColor: '#ffffff1a',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '40px',
		'@media (max-width: 414px)': {
			height: 'auto',
			padding: '16px',
			gap: '20px',
		},
		'@media (max-width: 1000px)': {
			height: 'auto',
			padding: '24px',
			gap: '20px',
		}
	},
	'& .subscribeContainer p': {
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '20px',
		color: '#fff',
		width: '436px',
		textAlign: 'justify',
		margin: 0,
		'@media (max-width: 414px)': {
			fontSize: '10px',
			lineHeight: '16px',
			width: '100%',
		}
	},
	'& .title': {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '28px',
		color: '#fff',
		'@media (max-width: 414px)': {
			fontSize: '12px',
			lineHeight: '24px',
		},
		'@media (max-width: 1280px)': {
			fontSize: '14px',
			lineHeight: '26px',
		},
		'@media (min-width: 1600px)': {
			fontSize: '18px',
			lineHeight: '28px',
		}
	},
	'& .emailContainer': {
		position: 'relative',
		width: '466px',
		'@media (max-width: 414px)': {
			width: '100%',
		}
	},
	'& .emailInput': {
		backgroundColor: '#fff',
		width: '466px',
		height: '52px',
		padding: '10px 54px 10px 10px',
		borderRadius: '6px',
		fontSize: '14px',
		lineHeight: '18px',
		color: '#64748b',
		'@media (max-width: 414px)': {
			fontSize: '10px',
			lineHeight: '14px',
			width: '100%',
			height: 'auto',
		}
	},
	'& .emailInput::placeholder': {
		fontSize: '14px',
		lineHeight: '18px',
		color: '#64748b',
		'@media (max-width: 414px)': {
			fontSize: '10px',
			lineHeight: '14px',
		},
		'@media (max-width: 1280px)': {
			fontSize: '12px',
			lineHeight: '16px',
		},
		'@media (min-width: 1600px)': {
			fontSize: '16px',
			lineHeight: '20px',
		}
	},
	'& .rightArrIcon': {
		position: 'absolute',
		top: 0,
		right: 0,
		width: '50px',
		height: '52px',
		backgroundColor: '#652784',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '0 5px 5px 0',
		'@media (max-width: 414px)': {
			height: '43px',
		}
	},
	'& .rightArrIcon svg': {
		fill: '#fff',
		cursor: 'pointer',
	},
	'& .socialLinks': {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		'@media (max-width: 1160px)': {
			flexDirection: 'row',
		}
	},
	'& .socialLinks div': {
		width: '50px',
		height: '50px',
		backgroundColor: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50px',
		boxShadow: '0px 7px 10px 0px rgba(75, 75, 75, 0.1)',
	},
	'& .socialLinks svg': {
		fill: '#652784',
		cursor: 'pointer',
	},
	'& .copyright': {
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '26px',
		color: '#fff',
		marginTop: '20px',
		'@media (max-width: 414px)': {
			fontSize: '12px',
			lineHeight: '22px',
		},
		'@media (max-width: 1280px)': {
			fontSize: '12px',
			lineHeight: '24px',
		},
		'@media (min-width: 1600px)': {
			fontSize: '16px',
			lineHeight: '28px',
		}
	},
});

export default class AppHeader extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <TypographyView>
        <Box className="footer">
          <Box className="footerContainer">
            <Box className="footerLogo">
              <Box component="img" src={require('./bharat_stage_logo.png')} alt="logo" />
            </Box>
            <Box className="footerLinksContainer">
              <Typography className='quickLinks'>Quick Links</Typography>
              <Button onClick={() => this.props.navigation.navigate('Contactus')}>
                Terms & Conditions
              </Button>
              <Button onClick={() => this.props.navigation.navigate('Contactus')}>
                Privacy Policy
              </Button>
              <Button onClick={() => this.props.navigation.navigate('Contactus')}>
                Contact Us
              </Button>
              <Button onClick={() => this.props.navigation.navigate('Contactus')}>
                Pricing
              </Button>
            </Box>
            <Box className="subscribeContainer">
              <Typography variant='h6' className='title'>Subscribe</Typography>
              <Box className="emailContainer">
                <Input
                  data-test-id={'inputName'}
                  placeholder='Email address'
                  className='emailInput'
                />
                <Box className="rightArrIcon">
                  <ArrowForwardRoundedIcon />
                </Box>
              </Box>
              <Box component='p'>Hello, we are Lift Media. Our goal is to translate the positive effects from revolutionizing how companies engage with their clients & their team.</Box>
            </Box>
            <Box className="socialLinks">
              <Box>
                <Facebook />
              </Box>
              <Box>
                <Instagram />
              </Box>
              <Box>
                <Twitter />
              </Box>
            </Box>
          </Box>
          <Typography className='copyRight'>© 2024 Bharat Stage. All rights reserved.</Typography>
        </Box>
      </TypographyView>
    )
  }
}