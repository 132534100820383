// Customizable Area Start
import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Mic,
  PhotoCamera,
  ScreenShare,
  RadioButtonChecked,
  Stop,
  CallEnd,
} from "@mui/icons-material";

import ExternalVideo from "./ExternalVideo.web";
import Participants from "./Participants";
import Connections from "./Connections";
import MeetingChat from "./MeetingChat";

const configJSON = require("./config");

const MeetingView = ({
  participantViewVisible,
  meetingId,
  toggleMic,
  toggleWebcam,
  toggleScreenShare,
  value,
  setValue,
  handleStartRecording,
  handleStopRecording,
  confirmLeave,
}) => {
  const theme = useTheme();

  return (
    <>
      <div
        style={{
          ...webStyles.mainContainer,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography variant="h3" style={webStyles.meetingId}>
          Meeting ID: {meetingId}
        </Typography>
        <div style={webStyles.container}>
          <div
            style={{
              ...webStyles.participantsContainer,
              height: `calc(100vh - ${configJSON.toolbarHeight}px)`,
            }}
          >
            <ExternalVideo />
            {participantViewVisible ? (
              <Participants data-testid="participants" />
            ) : (
              <Connections />
            )}
          </div>
          <MeetingChat />
        </div>
        <Paper sx={webStyles.bottomNavigation} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            data-testid="bottomNavigation"
          >
            <BottomNavigationAction
              label="Mic"
              icon={<Mic />}
              onClick={toggleMic}
            />
            <BottomNavigationAction
              label="Webcam"
              icon={<PhotoCamera />}
              onClick={toggleWebcam}
              data-testid="webcam"
            />
            <BottomNavigationAction
              label="ScreenShare"
              icon={<ScreenShare color="success" />}
              onClick={toggleScreenShare}
              data-testid="ScreenShare"
            />
            <BottomNavigationAction
              label="Start Recording"
              icon={<RadioButtonChecked />}
              onClick={handleStartRecording}
              data-testid="startRecording"
            />
            <BottomNavigationAction
              label="Stop Recording"
              icon={<Stop />}
              onClick={handleStopRecording}
              data-testid="stopRecording"
            />
            <BottomNavigationAction
              label="End Call"
              icon={<CallEnd color="error" />}
              onClick={confirmLeave}
              data-testid="confirmLeave"
            />
          </BottomNavigation>
        </Paper>
      </div>
    </>
  );
};

const webStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  meetingId: {
    color: "#706694",
  },
  container: {
    display: "flex",
    flex: 1,
  },
  participantsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
  },
  bottomNavigation: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
  },
};

export default MeetingView;
// Customizable Area End
