import React from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  ListItemText,
  ListItem,
  List,
  Drawer,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { bsLogo } from "./assets";
import NavButton from "../../../components/src/NavButton";

export const TypographyView = styled(Typography)({
  '& .navContainer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '80px',
    padding: '0 100px',
    backgroundColor: '#fff',
    '@media (max-width: 1279px)': {
      padding: '0 16px',
    }
  },
  '& .linksContainer': {
    display: 'flex',
    gap: '64px',
    '@media (max-width: 1023px)': {
      display: 'none !important',
    },

    '@media (min-width: 1024px) and (max-width: 1280px)': {
      gap: '30px',
    }
  },
  '& .links': {
    fontSize: '18px !important',
    fontWeight: 500,
    color: '#000 !important',
    fontFamily: "Roboto !important",
    textDecoration: 'none !important',

    '@media (max-width: 415px)': {
      fontSize: '14px !important',
    },

    '@media (min-width: 601px) and (max-width: 1280px)': {
      fontSize: '16px !important',
    }
  },
  '& .loginButton': {
    fontSize: '14px !important',
    fontWeight: 600,
    width: '150px !important',
    color: '#fff !important',
    fontFamily: "Roboto !important",
    lineHeight: '24px !important',
    border: 0,
    borderRadius: '32px',
    padding: '10px 39px !important',
    background: 'linear-gradient(90deg, #8077FF 0%, #652784 60%)',
    '@media (max-width: 1280px)': {
      fontSize: '12px',
    }
  },
  '& .signUpButton': {
    fontSize: '14px !important',
    fontWeight: 600,
    width: '150px !important',
    color: '#652784 !important',
    fontFamily: "Roboto !important",
    lineHeight: '24px !important',
    borderRadius: '32px',
    padding: '10px 39px !important',
    border: '.2rem solid transparent !important',
    background: 'linear-gradient(to right, white, white), linear-gradient(90deg, #8077FF 0%, #652784 60%)',
    backgroundClip: 'padding-box, border-box',
    backgroundOrigin: 'padding-box, border-box',
    '@media (max-width: 1280px)': {
      fontSize: '12px',
    }
  },
  '& .btnContainer': {
    display: 'flex',
    gap: '10px',
    '@media (max-width: 1023px)': {
      display: 'none !important',
    }
  },
  '& .hamburgerMenu': {
    display: 'none !important',
    '@media (max-width: 1023px)': {
      display: 'block !important',
    }
  },
});
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDrawerContent = () => (
    <Box
      role="presentation"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '260px',
      }}
    >
      <List>
        <ListItem>
          <ListItemText>
            <NavButton
              classname={''}
              sx={{
                fontSize: '18px !important',
                fontWeight: 500,
                color: '#000 !important',
                fontFamily: "Roboto !important",
                textDecoration: 'none !important',
                '@media (max-width: 415px)': {
                  fontSize: '14px !important',
                },
                '@media (min-width: 601px) and (max-width: 1280px)': {
                  fontSize: '16px !important',
                },
              }}
              path="HomeScreen"
              dataTestId="home-button"
              onClick={this.handleNavigation}
            >
              Home
            </NavButton>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <NavButton
              classname={''}
              sx={{
                fontSize: '18px !important',
                fontWeight: 500,
                color: '#000 !important',
                fontFamily: "Roboto !important",
                textDecoration: 'none !important',
                '@media (max-width: 415px)': {
                  fontSize: '14px !important',
                },
                '@media (min-width: 601px) and (max-width: 1280px)': {
                  fontSize: '16px !important',
                }
              }}
              path="Contactus"
              dataTestId="browser-artists-button"
              onClick={this.handleNavigation}
            >
              Browse Artists
            </NavButton>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <NavButton
              classname={''}
              sx={{
                fontSize: '18px !important',
                fontWeight: 500,
                color: '#000 !important',
                fontFamily: "Roboto !important",
                textDecoration: 'none !important',
                '@media (max-width: 415px)': {
                  fontSize: '14px !important',
                },
                '@media (min-width: 601px) and (max-width: 1280px)': {
                  fontSize: '16px !important',
                }
              }}
              path="Contactus"
              dataTestId="about-us-button"
              onClick={this.handleNavigation}
            >
              About Us
            </NavButton>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <NavButton
              classname={''}
              sx={{
                fontSize: '18px !important',
                fontWeight: 500,
                color: '#000 !important',
                fontFamily: "Roboto !important",
                textDecoration: 'none !important',
                '@media (max-width: 415px)': {
                  fontSize: '14px !important',
                },
                '@media (min-width: 601px) and (max-width: 1280px)': {
                  fontSize: '16px !important',
                }
              }}
              path="Contactus"
              dataTestId="contact-us-button"
              onClick={this.handleNavigation}
            >
              Contact Us
            </NavButton>
          </ListItemText>
        </ListItem>
      </List>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          paddingBottom: '16px',
        }}
      >
        <NavButton
          classname={''}
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            width: '150px',
            color: '#fff',
            fontFamily: "Roboto",
            lineHeight: '24px',
            border: 0,
            borderRadius: '32px',
            padding: '10px 39px',
            background: 'linear-gradient(90deg, #8077FF 0%, #652784 60%)',
            '@media (max-width: 1280px)': {
              fontSize: '12px',
            },
          }}
          path="Contactus"
          dataTestId="login-button"
          onClick={this.handleNavigation}
        >
          Log In
        </NavButton>
        <NavButton
          classname={''}
          sx={{
            fontSize: '14px !important',
            fontWeight: 600,
            width: '150px !important',
            color: '#652784 !important',
            fontFamily: "Roboto !important",
            lineHeight: '24px !important',
            borderRadius: '32px',
            padding: '10px 39px !important',
            border: '.2rem solid transparent !important',
            background:
              'linear-gradient(to right, white, white), linear-gradient(90deg, #8077FF 0%, #652784 60%)',
            backgroundClip: 'padding-box, border-box',
            backgroundOrigin: 'padding-box, border-box',
            '@media (max-width: 1280px)': {
              fontSize: '12px',
            },
          }}
          path="Contactus"
          dataTestId="signup-button"
          onClick={this.handleNavigation}
        >
          Sign Up
        </NavButton>
      </Box>
    </Box>
  );
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <TypographyView>
        <Box className="navContainer">
          <NavButton classname={''} onClick={this.handleNavigation} path="HomeScreen" dataTestId="home-button">
            <Box
              component="img"
              src={bsLogo}
              alt="Logo"
              sx={{ cursor: 'pointer' }}
            />
          </NavButton>

          <Box className="linksContainer">
            <NavButton
              classname="links"
              path="HomeScreen"
              dataTestId="home-button"
              onClick={this.handleNavigation}
            >
              Home
            </NavButton>
            <NavButton
              classname="links"
              path="Contactus"
              dataTestId="browser-artists-button"
              onClick={this.handleNavigation}
            >
              Browse Artists
            </NavButton>
            <NavButton
              classname="links"
              path="Contactus"
              dataTestId="about-us-button"
              onClick={this.handleNavigation}
            >
              About Us
            </NavButton>
            <NavButton
              classname="links"
              path="Contactus"
              dataTestId="contact-us-button"
              onClick={this.handleNavigation}
            >
              Contact Us
            </NavButton>
          </Box>

          <Box className="btnContainer">
            <NavButton
              classname="loginButton"
              path="Contactus"
              dataTestId="login-button"
              onClick={this.handleNavigation}
            >
              Log In
            </NavButton>
            <NavButton
              classname="signUpButton"
              path="Contactus"
              dataTestId="signup-button"
              onClick={this.handleNavigation}
            >
              Sign Up
            </NavButton>
          </Box>
          <IconButton
            className="hamburgerMenu"
            onClick={this.toggleDrawer}
            aria-label="menu"
            data-test-id="btnToggleDraw"
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={this.state.webDrawer}
            onClose={this.toggleDrawer}
          >
            {this.renderDrawerContent()}
          </Drawer>
        </Box>
      </TypographyView>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
