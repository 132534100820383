export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const sideimgonsignup1 = require("../assets/image_1.png");
export const sideimgonsignup2 = require("../assets/image_2.png");
export const sideimgonsignup3 = require("../assets/image_3.png");
export const sideimgonsignup4 = require("../assets/image_4.png");
export const sideimgonsignup5 = require("../assets/image_5.png");
export const sideimgonsignup6 = require("../assets/image_6.png");
export const sideimgonsignup7 = require("../assets/image_7.png");

