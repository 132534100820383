import React from "react";

// Customizable Area Start
import {
    Box,
    TextField,
    Typography,
    MenuItem,
    Button,
    IconButton,
    Select,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    CardMedia,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { sideimgonsignup1, sideimgonsignup2, sideimgonsignup3, sideimgonsignup4, sideimgonsignup5, sideimgonsignup6, sideimgonsignup7 } from "./assets";
import { Grid, Card } from '@mui/material';

// Customizable Area End


import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        const images = [sideimgonsignup1, sideimgonsignup2, sideimgonsignup3, sideimgonsignup4, sideimgonsignup5, sideimgonsignup6, sideimgonsignup7];
        return (
            <Box
                sx={{

                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#EFF6FF",
                }}
            >
                <Box
                    component="form"
                    sx={{
                        width: '772px',
                        backgroundColor: "#FFFFFF",
                        borderRadius: 2,
                        margin: '25px 60px',
                        padding: '30px 45px',
                        height: "fit-content",

                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 600,
                            fontSize: "28px",
                            lineHeight: "32px",
                            letterSpacing: "-0.12px",
                        }}
                    >
                        Welcome to Bharat Stage
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: "#64748B",
                            lineHeight: "20px",
                            marginTop: "20px",
                        }}
                    >
                        Enter your details to sign up!
                    </Typography>

                    <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                        <Box style={{ maxWidth: '340px', width: '100%' }}>
                            <Typography
                                sx={{ fontSize: "14px" }}>First Name</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="First Name"
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                                }}
                            />
                        </Box>
                        <Box style={{ maxWidth: '340px', width: '100%' }}>
                            <Typography
                                sx={{ fontSize: "14px" }}>Last Name</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Last Name"
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                                }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                        <Box style={{ maxWidth: '340px', width: '100%' }}>
                            <Typography
                                sx={{ fontSize: "14px" }}>Email Address</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="example@example.com"
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                                }}
                            />
                        </Box>

                        <Box style={{ maxWidth: '340px', width: '100%' }}>
                            <Typography
                                sx={{ fontSize: "14px" }}>Mobile Number</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="+91 9876543210"
                                InputProps={{
                                    endAdornment: true && (
                                        <InputAdornment position="end">
                                            <Typography
                                                sx={{
                                                    color: "green",
                                                    fontWeight: "bold",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Verified
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                                }}
                            />
                        </Box>
                    </Box>


                    <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                        <Box style={{ maxWidth: '340px', width: '100%' }}>
                            <Typography
                                sx={{ fontSize: "14px" }}>Password </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your password"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton >
                                            <VisibilityOffIcon/>
                                            <VisibilityIcon />
                                        </IconButton>
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                                }}
                            />
                        </Box>
                        <Box style={{ maxWidth: '340px', width: '100%' }}>
                            <Typography
                                sx={{ fontSize: "14px" }}>Confirm Password</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Re-enter your password"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton>
                                            <VisibilityIcon />
                                            <VisibilityOffIcon/>
                                        </IconButton>
                                    ),
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                                }}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ marginTop: 2 }}>
                        <Select
                            fullWidth
                            defaultValue="Artist"
                            sx={{
                                borderRadius: "32px",
                                "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                            }}
                        >
                            <MenuItem value="Artist">Artist</MenuItem>
                            <MenuItem value="Viewer">Viewer</MenuItem>
                            <MenuItem value="Organizer">Organizer</MenuItem>
                        </Select>
                    </Box>

                    <Box sx={{ marginTop: 2 }}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="I have read and agree to the Terms and Conditions"
                            sx={{
                                color: "#6200EE",
                                fontSize: "14px",
                            }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                background: "linear-gradient(45deg, #6a11cb, #2575fc)",
                                color: "#FFFFFF",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderRadius: "36px",
                                paddingY: 1.5,
                                marginTop: 2,
                                maxWidth: "360px",
                            }}
                        >
                            Sign Up
                        </Button>
                    </Box>

                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: "center",
                            color: "#999",
                            marginY: 2,
                        }}
                    >
                        ——————————— or ——————————
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: "center",
                        }}
                    >
                        Already have an account?{" "}
                        <Button
                            variant="text"
                            sx={{
                                textTransform: "none",
                                fontWeight: "bold",
                                color: "#6200EE",
                            }}
                            onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}
                        >
                            Log In
                        </Button>
                    </Typography>
                </Box>

                <Box
                    sx={{

                        borderRadius: '12px',
                        overflow: 'hidden',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '20px',
                    }}
                >
                    <Grid container direction="column" spacing={2} sx={{marginTop: '-45px',}}>
                        {images.slice(0, 3).map((src, index) => (
                            <Grid item key={index}>
                                <Card sx={{ borderRadius: '12px', overflow: 'hidden'}}>
                                    <CardMedia
                                        component="img"
                                        image={src}
                                        alt={`gallery-img-${index}`}
                                        sx={{
                                            maxHeight: '330px',
                                            objectFit: 'cover',
                                            maxWidth: "223px",
                                        }}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container direction="column" spacing={2} sx={{marginTop: '-80px',}}>
                        {images.slice(3, 7).map((src, index) => (
                            <Grid item key={index}>
                                <Card sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        image={src}
                                        alt={`gallery-img-${index + 3}`}
                                        sx={{
                                            maxHeight: '330px',
                                            objectFit: 'cover',
                                            maxWidth: "223px",
                                        }}
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        )
    }
}

