// Customizable Area Start
import React from "react";
import { Typography } from "@mui/material";
import ReactPlayer from "react-player";

const ParticipantView = ({
  webcamRef,
  micRef,
  screenShareRef,
  displayName,
  webcamOn,
  screenShareOn,
  isLocal,
  webcamMediaStream,
  screenShareMediaStream,
  onError,
}) => {
  console.log("screenShareMediaStream", screenShareMediaStream, onError);
  return (
    <div style={webStyles.container}>
      <audio ref={micRef} autoPlay muted={isLocal} />

      <div style={webStyles.camContainer}>
        <div style={webStyles.video}>
          <>
            <ReactPlayer
              ref={webcamRef}
              playsinline
              playIcon={<></>}
              pip={false}
              light={false}
              controls={false}
              muted
              playing
              url={webcamMediaStream}
              height="100%"
              width="100%"
              onError={onError}
              data-testid="webCam"
            />
          </>
          <div style={webStyles.webCamPopup}>
            <Typography
              variant="h6"
              style={{
                ...webStyles.webCamPopupText,
                color: webcamOn ? "green" : "red",
              }}
            >
              WEB CAM
            </Typography>
          </div>

          <div style={webStyles.displayName}>
            <Typography>{displayName}</Typography>
          </div>
        </div>
      </div>

      <div style={webStyles.screenShareContainer}>
        <div style={webStyles.screenShare}>
          <>
            <ReactPlayer
              ref={screenShareRef}
              playsinline
              playIcon={<></>}
              pip={false}
              light={false}
              controls={false}
              muted
              playing
              url={screenShareMediaStream}
              height="100%"
              width="100%"
              onError={onError}
              data-testid="screenShare"
            />
          </>
          <div style={webStyles.screenSharingTextWrapper}>
            <p
              style={{
                ...webStyles.screenSharingText,
                color: screenShareOn ? "green" : "red",
              }}
            >
              SCREEN SHARING
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const webStyles = {
  container: {
    width: 400,
    backgroundColor: "#3E84F6",
    overflow: "hidden",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    borderRadius: 8,
    margin: 8,
    padding: 8,
  },
  camContainer: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: "500px",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  webCamPopup: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  webCamPopupText: {
    fontSize: 16,
    fontWeight: "bold",
    opacity: 1,
  },
  displayName: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "#3e84f6",
  },
  screenShareContainer: {
    marginTop: 8,
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: "100%",
  },
  screenShare: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  screenSharingTextWrapper: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  screenSharingText: {
    fontSize: 16,
    fontWeight: "bold",
    opacity: 1,
  },
};
export default ParticipantView;
// Customizable Area End
