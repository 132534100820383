import React from "react";
import {
  Button,
} from '@mui/material';

export interface ReusableProps {
	children: React.ReactNode;
	path: string;
	onClick: (path: string) => void;
	dataTestId: string;
	classname: any;
	sx?: any;
}

export default class NavButton extends React.Component<ReusableProps> {
	render() {
		const { children, classname, dataTestId, onClick, path, sx } = this.props;

		return (
			<Button
				className={classname}
				onClick={() => onClick(path)}
				data-test-id={dataTestId}
				sx={sx}
			>
				{children}
			</Button>
		)
	}
}