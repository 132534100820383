import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { View, StyleSheet } from "react-native";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#F5F5F5",
          padding: 2,

        }}
      >
        <Box
          component="form"
          // onSubmit={this.handleSubmit}
          sx={{
            maxWidth: "500px",
            backgroundColor: "#FFFFFF",
            borderRadius: 2,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            padding: 5,
            marginTop: '40px',
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, fontSize:"28px", lineHeight:"32px", letterSpacing:"-0.12px", width:"100%", maxWidth:"374px" }}
          >
            Welcome to Bharat Stage
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{color: "#64748B", width:"356px", lineHeight:"20px", marginTop:"20px" }}
          >
            Please enter your login details
          </Typography>
          <Box 
          sx={{marginTop:"16px"}}>
            <Typography 
            sx={{lineHeight:"22px" }}>Email Address</Typography>
            <TextField
              fullWidth
              placeholder="BharatStage@123"
              variant="outlined"
              name="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "32px",
                },
              }}
            />
          </Box>

          <Box sx={{ position: "relative", marginTop:"16px" }}>
          <Typography
          sx={{lineHeight:"22px" }}>Password</Typography>
            <TextField
              fullWidth
              placeholder="BharatStage@123"
              variant="outlined"
              type="password"
              name="password"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "32px",
                },
              }}

            />
            <IconButton
              sx={{ position: "absolute", top: 30, right: 20 }}
              >
              <VisibilityIcon/>
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginY: 2,
            }}
          >
            <Button
              variant="text"
              sx={{ textTransform: "none", color: "#6200EE" }}
            >
              Forgot Password?
            </Button>
          </Box>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              background: "linear-gradient(45deg, #6a11cb, #2575fc)",
              color: "#FFFFFF",
              fontWeight: "bold",
              textTransform: "none",
              borderRadius: 36,
              paddingY: 1.5,
              marginBottom: 2,
            }}
          >
            Log In
          </Button>

          <Typography
            variant="body2"
            sx={{
              color: "red",
              marginY: 1,
            }}
          >
           Invalid email or password. Please try again
          </Typography>
          

          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              color: "#999",
              marginY: 2,
            }}
          >
            — or —
          </Typography>


          <Typography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
            Don’t have an account?{" "}
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                color: "#6200EE",
              }}
            >
              Sign Up
            </Button>
          </Typography>
        </Box>
      </Box>

      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
    width: "374px",
    fontSizet: "28px",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px",
    maxWidth: "360px",
    width: "100%"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
