Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GroupVideoCall";
exports.labelBodyText = "GroupVideoCall Body";

exports.btnExampleTitle = "CLICK ME";

// VideoSDK Endpoints
exports.API_BASE_URL = "https://api.videosdk.live";
// exports.API_AUTH_URL = "https://group-video-calls.herokuapp.com";
exports.REACT_APP_VIDEOSDK_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI0NDRlMTliZS04ZjY2LTRjNWEtYmQ3ZC0yMmNlYjJhNWRiNWEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY2NDM0MjcyNywiZXhwIjoxNjY0OTQ3NTI3fQ.BgfVoXOcQPRwcbs57E_H0rfB-nUXfiu8GNCkQSKDXPA";

exports.apiContentType = "application/json";

// REST Endpoints
exports.getLoggedInUserEndPoint = "account/accounts/logged_user";
exports.getLoggedInUserMethod = "GET";

exports.getGroupsEndPoint = "account_groups/groups";
exports.getGroupsMethod = "GET";

exports.getUsersEndPoint = "roles_permissions/accounts/list_users";
exports.getUsersMethod = "GET";

exports.removeUserFromGroupEndPoint =
  "account_groups/groups/<GROUP_ID>/remove_accounts";
exports.removeUserFromGroupMethod = "POST";

exports.addUserToGroupEndPoint =
  "account_groups/groups/<GROUP_ID>/add_accounts";
exports.addUserToGroupMethod = "POST";

exports.createGroupEndPoint = "account_groups/groups";
exports.createGroupMethod = "POST";

exports.deleteGroupEndPoint = "account_groups/groups/";
exports.deleteGroupMethod = "DELETE";

exports.getMeetingIdEndPoint = "video_call/meeting_link/";
exports.getMeetingIdMethod = "GET";

// Labels
exports.addGroupText = "Add Group";
exports.groupNameText = "Group Name";
exports.groupMemberText = "Group Members";
exports.actionText = "Actions";
exports.addUserToGroupText = "Add User to group";
exports.deleteGroupText = "Delete group";
exports.joinMeetingText = "Join Meeting";
exports.addUserText = "Add User";
exports.cancelText = "Cancel";
exports.addText = "Add";
exports.selectUserLabelText = "Please select user";
exports.enterGroupNameLabelText = "Enter group name";
exports.notificationTitle = "Code Sample";
exports.notificationMessage = "Meeting is running";
exports.participantsLabel = "Participants";
exports.externalVideoLabel = "External Video";
exports.chatLabel = "Chat";
exports.sendLabel = "Send";
exports.toolbarHeight = 120;
exports.unathorizedGroupMsg = "You are not authorized to access this group."
// Customizable Area End
